<template>
  <div>
    <AppHeader></AppHeader>
    <div class="main-body">
      <div class="ticket-input__wrapper">
        <LvInput type="text" v-model="ticketId" bottomBar placeholder="Enter Ticket ID">
          <template #append>
            <LvButton @click="getTicketStauts" icon="light-icon-search" class="lv--primary" />
          </template>
        </LvInput>
      </div>
      <loading v-model:active="isLoading" :can-cancel="false" :is-full-page="true" color="#38b2ac" />
      <div v-if="!isLoading">
        <div v-if="invalidTicket">
          <div class="ticket-status --invalid">This is an invalid ticket. Please Check your ticket ID.</div>
        </div>
        <div class="di-ticket-wrapper" v-else>
          <!-- <MobilePreview v-if="bookingData" :ticketData="bookingData" :ticketId="ticketId" class="desktop-preview" />
            <DigitalTicket v-if="bookingData" :ticketData="bookingData" :ticketId="ticketId" class="mobile-preview" /> -->
          <BookingTicketStatus v-if="bookingData" :bookingData="bookingData" id="booking-ticket" />

          <div class="digital_ticket__button-wrapper" v-if="bookingData">
            <LvButton label="Download Ticket" @click="exportToPDF(ticketId)" class="lv--success" push />
          </div>
          <div class="digital_ticket__button-wrapper" v-if="bookingData && ticketId">
            <LvButton label="Get Confirmation Message" @click="generateMessage()" class="lv--secondary" push />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import AppHeader from '@/components/AppHeader.vue';
// import DigitalTicket from '@/components/DigitalTicket.vue';
import LvButton from 'lightvue/button';
import LvInput from 'lightvue/input/index.js';
import Loading from 'vue-loading-overlay';
import 'vue-loading-overlay/dist/css/index.css';
import appConfig from '@/config/index.ts';
// import domtoimage from 'dom-to-image-more';
import html2pdf from 'html2pdf.js';
// import MobilePreview from '@/components/MobilePreview.vue';
import BookingTicketStatus from '@/components/status/BookingTicketStatus.vue';
import { displayedFormattedNumber, getWhatsappMessageLink } from '@/utils';

export default {
  data() {
    return {
      ticketId: '',
      bookingData: null,
      ticketDetails: null,
      isLoading: false,
      invalidTicket: false,
    };
  },

  components: {
    AppHeader,
    // DigitalTicket,
    LvButton,
    LvInput,
    Loading,
    // MobilePreview,
    BookingTicketStatus,
  },
  mounted() {
    this.removeQueryParameters();
  },
  methods: {
    removeQueryParameters() {
      this.$router.push({ query: {} });
    },
    async getTicketStauts() {
      try {
        this.$router.push({ path: '', query: { ticket: this.ticketId } });
        this.isLoading = true;
        const storedData = await fetch(`${appConfig.API_BASE_URL}/v1/details/${this.ticketId}`, {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
          },
          body: JSON.stringify({
            internal: true,
          }),
        })
          .then(response => response.json())
          .then(data => {
            return data;
          });
        this.ticketDetails = storedData;
        this.isLoading = false;
        this.invalidTicket = false;
        this.getTicketDetails();
      } catch (error) {
        console.log(error);
        this.invalidTicket = true;
      }
    },
    getTicketDetails() {
      this.bookingData = {
        activitySlug: this.ticketDetails?.activity._slug,
        activityDate: this.ticketDetails?.basic_info.activity_date_iso,
        activityName: this.ticketDetails?.activity?.name,
        activitySlot: this.ticketDetails?.basic_info.activity_slot,
        activityVariant: this.ticketDetails?.package?.basic_info?.primary_specs,
        bookingId: this.ticketDetails?.booking_id,
        bookingDate: this.ticketDetails?.createdAt,
        bookingStatus: this.ticketDetails?.status?.is_reserved,
        primaryGuestName: this.ticketDetails?.basic_info.primary_guest_name,
        primaryGuestNumber: this.ticketDetails?.basic_info.primary_guest_number,
        providerAddress: this.ticketDetails?.provider?.location_info.region_name,
        providerCity: this.ticketDetails?.provider?.location_info.city_name,
        providerContact: this.ticketDetails?.provider?.contact_info?.public_number || '8006888600',
        providerLogo: this.ticketDetails?.provider?.basic_info?.logo,
        providerName: this.ticketDetails?.provider?.basic_info?.name,
        providerLocation: this.ticketDetails?.provider?.location_info.geo_coordinates,
        totalAdvancePay: this.ticketDetails?.prices.total_advance_pay || 0,
        totalSellingPrice: this.ticketDetails?.prices?.total_selling_price,
        totalDiscount: this.ticketDetails?.prices?.total_discount,
        unitCountBooked: this.ticketDetails?.basic_info.unit_count_booked,
      };
    },
    exportToPDF() {
      var element = document.getElementById('booking-ticket');
      var opt = {
        filename: `${this.ticketId}__rishikesh-app`,
        margin: 0,
        html2canvas: { useCORS: true },
        jsPDF: { orientation: 'p', unit: 'px', format: [320, 619], putOnlyUsedFonts: true },
      };
      html2pdf(element, opt);
    },
    // exportToPNG(ticketId) {
    //   domtoimage.toJpeg(document.getElementById('ticket'), { quality: 0.95 }).then(function (dataUrl) {
    //     var link = document.createElement('a');
    //     link.download = `${ticketId}.Rishikesh.app.jpeg`;
    //     link.href = dataUrl;
    //     link.click();
    //   });
    // },
    generateMessage() {
      const unitDiscount = this.ticketDetails.prices?.unit_discount || 0;
      const unitSp = this.ticketDetails.prices?.unit_selling_price || 0;
      const unitSpString = `₹${unitSp.toLocaleString()}/-`;
      const unitSpActualString = `~${unitSpString}~ ₹${(unitSp - unitDiscount).toLocaleString()}/-`;

      const totalDiscount = this.ticketDetails.prices?.total_discount || 0;
      const totalSp = (this.ticketDetails.prices?.unit_selling_price || 0) * (this.ticketDetails.basic_info?.unit_count_booked || 0);
      const totalSpString = `₹${totalSp.toLocaleString()}/-`;
      const totalSpActualString = `~${totalSpString}~ ₹${(totalSp - totalDiscount).toLocaleString()}/-`;
      const totalAdvancePaid = this.ticketDetails.prices?.total_advance_pay || 0;
      const activityDateStr = new Date(this.bookingData.activityDate).toDateString();
      const enquiryMessage = `
*Booking Confirmed* ✅
Booked from: RISHIKESH.app
---------------------------------------
    *Booking Id:* ${this.bookingData.bookingId}
    *Booking Name:* ${this.bookingData.primaryGuestName}
    *Phone:* ${displayedFormattedNumber(this.bookingData.primaryGuestNumber)}
    =======================
    *Activity Name:* ${this.bookingData.activityName}
    *Package:* ${this.ticketDetails.package?.basic_info?.common_name || this.ticketDetails.package?.basic_info?.name}
    *Activity Date:* ${activityDateStr}
    *Time:* ${this.bookingData.activitySlot}
    =======================
    *Price (per ${this.getTicketsUnitLabel()}):* ${unitDiscount ? unitSpActualString : unitSpString}
    *Number of ${this.getTicketsUnitLabel()}:* ${this.bookingData.unitCountBooked} pax
    *Total Price:* ${totalDiscount ? totalSpActualString : totalSpString}
    =======================
    *Advance Paid:* ₹${totalAdvancePaid.toLocaleString()}/- ✅
    *Remaining Amount:* ₹${(totalSp - totalDiscount - totalAdvancePaid).toLocaleString()}/-
    _(payable at the activity location)_
---------------------------------------
${this.getFooter1()}

${this.getFooter2()}
`;
      window.open(getWhatsappMessageLink(enquiryMessage), '_blank');
    },
    getTicketsUnitLabel() {
      return this.ticketDetails.package?.advance_info.unit_label || 'person';
    },
    getFooter1() {
      return this.ticketDetails.activity?._slug === 'river-rafting' ? `_₹20 per person additional as government fee payable at the time of rafting onboard_` : `_PS: Videography can be opted directly at the activity location (additional chargeable)._`;
    },
    getFooter2() {
      return this.ticketDetails.activity?._slug === 'river-rafting' ? `_Please reach us on: +91-8006888600, if needed any help_` : `_Please note that the above mentioned time is tentative. Actual activity will happen on first-come first-do basis_`;
    },
  },
};
</script>

<style lang="scss">
@import url('https://fonts.googleapis.com/css2?family=Nunito:ital,wght@0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;0,1000;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900;1,1000&display=swap');
@import '~light-icons/dist/light-icon.css';
* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}
#app {
  font-family: 'Nunito', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
}
.main-body {
  padding-top: 16px;
  display: flex;
  align-items: center;
  flex-direction: column;
  overflow: hidden;
  margin-top: 60px;
}
.ticket-input__wrapper {
  display: flex;
  gap: 50px;
  justify-content: center;
  align-items: flex-end;
  position: relative;
  padding-bottom: 16px;
  max-width: 320px;
  .lv-input__group {
    width: 450px;
    max-width: 550px;
  }
}
.loader__wrapper {
  position: absolute;
  top: 50%;
  bottom: 0;
  left: 0;
  right: 0;
}
@media (max-width: 568px) {
  .ticket-input__wrapper {
    gap: 10px;
    width: 100%;
    .lv-input__group {
      width: 100%;
    }
  }
}
.digital_ticket__button-wrapper {
  padding: 12px 0px;
  .lv-button {
    width: 100%;
  }
  .digital_ticket__print {
    padding: 12px 40px;
    display: flex;
    justify-content: center;
    background: #143a56;
    font-size: 18px;
    font-weight: 500;
    border-radius: 4px;
    color: white;
    cursor: pointer;
    &:hover {
      background-color: #c9d0d0;
      color: #143a56;
    }
  }
}
.mobile-preview {
  display: none;
}
@media (max-width: 568px) {
  .mobile-preview {
    display: block;
  }
  .desktop-preview {
    display: none;
  }
  .digital_ticket__button-wrapper {
    padding: 10px;
    .digital_ticket__print {
      padding: 10px 20px;
      font-size: 16px;
    }
  }
  .ticket-status {
    display: flex;
    justify-content: center;
    color: #c83535;
    font-weight: 500;
    padding: 16px;
  }
}
</style>
